import "lazysizes";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {    
	setupSlideMenu();
	setupFAQ();
	setupBannerNavigation();
	window.kms_bannerRotate();
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
};

export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			$("body").toggleClass("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");

		}
	});

	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});

}
export function setupFAQ(){
	const faq_cards = document.querySelectorAll(".faq-item");
	let first_flag = true;
	if(faq_cards){
		faq_cards.forEach(el => {
			let title = el.querySelector(":scope > .faq-title");
			if (title){
				title.addEventListener("click", () => {
					el.classList.toggle("fold");
				});
			}
			let content = el.querySelector(":scope > .faq-content");
			content.style.maxHeight = content.clientHeight + "px";
			if(first_flag){
				first_flag = false;
			}
			else{
				el.classList.add("fold");
			}
		});
	}	
}

export function setupBannerNavigation(){
	
	const banners = document.querySelectorAll(".banners");
	if(!banners){
		return;
	}
	banners.forEach(el => {
		const items = el.querySelectorAll(".banner-stage > *");
		if (items == null | items.length == 1){
			el.querySelector(".banner-navigation").style.display = "none";
			el.querySelector(".arrow-wrapper").style.display = "none";
		}
	});
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});
